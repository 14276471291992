import React from 'react'
import {isIOS, isAndroid} from 'react-device-detect'
import buttonAndroid from '../../assets/imgs/icon_android.png'
import buttonIOS from '../../assets/imgs/icon_apple.png'
import style from './app.module.scss'
import * as locales from '../../locales'
import * as config from '../../config'

const DEFAULT_LOCALE = 'en_US'

const IntlDownloader = ({locale}) => {
  let downloadBtn
  const messages = locales[locale] ? locales[locale].messages : locales[DEFAULT_LOCALE].messages
  if (isIOS) {
    downloadBtn = (
      <a className={style['download-link']} href={config.downloadLinkIOSMobile}>
        <img src={buttonIOS}/> <span>{messages.downloadIos}</span>
      </a>
    )
  }

  if (isAndroid) {
    downloadBtn = (
      <a className={style['download-link']} href={config.downloadLinkAndroid}>
        <img src={buttonAndroid}/> <span>{messages.downloadAndroid}</span>
      </a>
    )
  }

  if (!isIOS && !isAndroid) {
    downloadBtn = <div className={style['link-group']}>
      <a className={style['download-link']} href={config.downloadLinkIOS}>
        <img src={buttonIOS}/> <span>{messages.downloadIos}</span>
      </a>
      <a className={style['download-link']} href={config.downloadLinkAndroid}>
        <img src={buttonAndroid}/> <span>{messages.downloadAndroid}</span>
      </a>
    </div>
  }
  return (
    <div className={style['background']}>
      <h1>{messages.title}</h1>
      <footer>
        <p>{messages.description}</p>
        {downloadBtn}
      </footer>
    </div>
  )
}

export default IntlDownloader
