import React from 'react'
import ReactDOM from 'react-dom'
import qs from 'qs'
import './theme.scss'
import AppDownload from './app'
import * as locales from '../../locales'
import acceptedlanguages from 'acceptedlanguages'
import { PE_HOMEPAGE_ENV_URL } from '../../config'
import { getENVFromDomain } from '../../utils'

let locale = window.location.pathname.split('/')[1]
let lang
if (acceptedlanguages.accepted) {
  console.log('acceptedlanguages =>', acceptedlanguages.accepted)
  acceptedlanguages.accepted.forEach(l => {
    if (lang) {
      return
    }
    l = l.split(/[-_]/).map((c, index) => {
      if (index === 1) {
        return c.toUpperCase()
      } else {
        return c
      }
    }).join('_')
    if (locales[l]) {
      lang = l
    }
    if (!lang) {
      const found = Object.keys(locales).find(i => i.split('-')[0] === l)
      if (found) {
        lang = found
      }
    }
  })
}
locale = locale ? locale : locales[lang] ? lang : 'en_US'

const query = qs.parse(window.location.search.replace(/^\?/, ''))

if (query.qr_type && /^pe/.test(query.qr_type)) {
  /*if is PE env, then forward to PE homepage*/
  window.location.replace(`${PE_HOMEPAGE_ENV_URL(getENVFromDomain())}${window.location.search}`)
}

ReactDOM.render(
  <React.StrictMode>
    <AppDownload locale={locale}/>
  </React.StrictMode>,
  document.getElementById('root')
)

