export const objectToString = (input) => {
  /*DEMO UTIL FUNC*/
  return String(input)
}


export const getENVFromDomain = () => {
  const host = window.location.host
  if (host.includes('app-intl-dev.')) {
    return 'dev'
  }
  if (host.includes('app-intl-test.')) {
    return 'test'
  }
  if (host.includes('app-intl-stg.')) {
    return 'stg'
  }
  if (host.includes('app-intl.')) {
    return 'prod'
  }
  return 'dev'
}